@use '../../sass/colors';

.topLevel {
  //display: flex;
  padding: 1em;
  background-color: clear;
  flex-direction: column;
}

.socialButtonsContainer {
  display: inline-flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
  width: min-content;
  padding: 2em;
}